// AllightSykes

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "AllightSykes",
      ampFund: true,
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          AMP Signature Super with AllightSykes
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            By joining the company Superannuation fund, you benefit from group
            discount rates on fees and insurances. This means you pay much
            less than you would pay in a personal or individual plan.
          </p>
          <p>
            This website provides all the information you need around the
            AllightSykes Superannuation Fund. There are helpful super
            tools and calculators, and information on other services we offer
            if you require additional support with your financial well-being.
          </p>
          <div class="col-lg-6 pt-4 pb-1 px-0 text-center text-lg-center">
            <a
              target="_blank"
              href="https://www.amp.com.au/amp/performance-and-unit-prices/superannuation-funds/signaturesuper"
              class="btn-learn-more"
            >
              Learn more about the AMP Super fund
            </a>
          </div>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p><b>Fees (per annum)</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Member Fee:
              <b>Nil</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Administration Fee:
              <b>0.14%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Investment
              Fee:
              <b>0.26%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> TOTAL:
              <b>0.40%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Indirect
              Cost Ratio: <b>0.28% - 0.62%</b>
            </li>
          </ul>
          <small>
            Indirect Cost Ratios are deducted from investment returns prior to
            calculation of daily unit prices. For full fee disclosure refer to
            the Fund Product Disclosure Statement (PDS)
          </small>
          <p>
            <b>Insurances</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <p><strong>Death & Total Permanent Disability Cover</strong></p>
              <strong>Staff:</strong>
              Sum insured = 15% x salary x years to age 65
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <p><strong>Income Protection</strong></p>
              Insures 75% x salary with 90 day waiting period and 2 year benefit period
            </li>
          </ul>
          <p>
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              AMP MySuper Lifecycle
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="https://www.amp.com.au/amp/performance-and-unit-prices/superannuation-funds/signaturesuper" target="_blank">AMP Signature Super</a>
            </li>
          </ul>
        </div>
      </div>
    `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Building_Your_Super_Factsheet_2023-2024.pdf",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "info",
        url: "AMP_Signature_Super_pds_01072023.pdf",
      },
      {
        title: "Member Guide Fact Sheet",
        description: "Additional information on your super fund.",
        type: "form",
        url: "sg_mbrguide_01072023.pdf",
      },
      {
        title: "Beneficiary Nomination",
        description:
          "Do you need to nominate a beneficiary on your super fund?",
        type: "form",
        url: "AMP_80000.3_Beneficiary_nomination.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Additional Insurance Cover",
        description:
          "Use this form to apply for additional Death and if applicable Total and Permanent Disablement insurance cover.",
        type: "form",
        url: "904834_F_Changing_your_insurance_arrangements.pdf",
      },
      {
        title: "Investment Selection",
        description: "Do you want to change your investment choice?",
        type: "form",
        url: "AMP_80009.6_F_Investment_options_selection.pdf",
      },
      {
        title: "Investment Guide Fact Sheet",
        description: "Additional information on your super fund.",
        type: "form",
        url: "AMP_sg_invguide_July_2023.pdf",
      },
      {
        title: "Join the AllightSykes fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "ALLIGHTSYKES_PTY_LTD-New_Employee_Pack_October_2023.pdf",
      },
      {
        title: "AMP Plan Summary",
        description:
          "The information in this Plan Summary describes the features and benefits specific to AllightSykes Superannuation Plan.",
        type: "info",
        url: "Westrac_Category_4_Allight_Staff_July_2023.pdf",
      },
    ],
    optIn: "AMP_Insurance_OPT-IN_Form.pdf",
    footerClientName: "AllightSykes Pty Ltd",
  },
  superscore: true,
  team: ["anthony", "phil", "silvia", "natasha"],

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
